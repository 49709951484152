import { DzSectionMenu as DzSectionMenuBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzSectionMenuProps = ComponentProps<typeof DzSectionMenuBasic>
type Props = Omit<DzSectionMenuProps, 'LinkElement'>

export const DzSectionMenu = (props: Props) => {
  const dzSectionMenuProps = { ...props, LinkElement: Link } as DzSectionMenuProps
  return <DzSectionMenuBasic {...dzSectionMenuProps} />
}

DzSectionMenu.displayName = 'DzSectionMenuWrapper'
