export const ABOUT_SECTION = 'About'
export const ABOUT_SLUG = '/about'
export const ARTIST_SECTION = 'Artist'
export const ARTISTS_SECTION = 'Artist'
export const HONG_HONG_SECTION = 'Hong Kong'
export const ARTISTS_SLUG = '/artists'
export const ARTWORKS_SECTION = 'Artworks'
export const AVAILABLE_ARTWORKS_SECTION = 'Available Artworks'
export const AVAILABLE_ARTWORKS_SLUG = '/available-artworks'
export const BOOKS_ABOUT_SECTION = 'Books - About'
export const BOOKS_ABOUT_SLUG = '/books/about'
export const BOOKS_ARTISTS_LISTING_SECTION = 'Books Artists Listing'
export const BOOKS_ARTISTS_LISTING_SLUG = '/books/artists'
export const BOOKS_HOME_SECTION = 'Books - Home'
export const BOOKS_HOME_SLUG = '/books'
export const COLLECT_ARTIST_SECTION = 'Collect - Artist'
export const COLLECT_BOOK_SECTION = 'Book'
export const COLLECT_SECTION = 'Collection'
export const COLLECT_SLUG = '/collect'
export const COLLECTION_SECTION = 'Collection'
export const CONSIGNMENTS_SECTION = 'Consignments'
export const CONSIGNMENTS_SLUG = '/consignments'
export const EXHIBITIONS_SECTION = 'Exhibition'
export const EXHIBITIONS_SLUG = '/exhibitions'
export const FAIRS_SECTION = 'Fairs'
export const FAIRS_SLUG = '/fairs'
export const GALLERIES_SECTION = 'Galleries'
export const GALLERY_SECTION = 'Gallery'
export const GALLERIES_SLUG = '/galleries'
export const HOME_SECTION = 'Home'
export const HOME_SLUG = 'home'
export const HONGKONG_SLUG = '/hongkong'
export const LANDING_SECTION = 'Landing'
export const MISSINGPAGE_SECTION = '404'
export const NEWS_SECTION = 'News'
export const NEWS_SLUG = '/news'
export const PODCASTS_SECTION = 'Podcast'
export const PODCASTS_SLUG = '/podcast'
export const PRIVACYPOLICY_SECTION = 'Privacy Policy'
export const PRIVACYPOLICY_SLUG = '/privacy-policy'
export const PRINTS_EDITIONS_SLUG = '/prints-editions'
export const STORIES_SLUG = '/stories'
export const SUBSCRIBE_SECTION = 'Subscribe'
export const SUBSCRIBE_SLUG = '/subscribe'
export const TERMS_SECTION = 'terms and conditions'
export const TERMS_SLUG = '/terms-and-conditions'
export const VIEWING_ROOM_SECTION = 'Viewing Room'
export const VIDEO_SLUG = '/video'
export const WECHAT_SECTION = 'Wechat'
export const WECHAT_SLUG = '/wechat'
